import * as React from "react";
import { observer } from "mobx-react";
import { List, ListItem, Collapse, ListItemText, Button } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { observable, computed } from "mobx";
import moment from "moment";
import { router, ValidPaths } from "../config/Router";
import {service, IWorkDayData} from "../service/timeTracker.service"
import BottomMenu from "./BottomMenu";

interface IShowWorkDayProps {
    workDay: IWorkDayData;
    postId: string;
}

@observer
export class ShowWorkDay extends React.Component<IShowWorkDayProps, any> {
    @observable _breaksOpen: boolean = false;
    @computed private get _totalBreakTime() {
        return service.getTotalBreakTime(this.props.workDay)
    };

    @computed get _totalHoursWorked() {
        return service.getWorkTimeForDay(this.props.workDay, "hrs");
    };

    private _renderBreaks = () => {
        return this.props.workDay.timeBreaks
            ? this.props.workDay.timeBreaks.map((tb, i) => {
                return <ListItem key={i} divider dense>
                <ListItemText primary={`from: ${tb.start} - to: ${tb.end}`} /></ListItem>
            }) : null;
    };

    render() {
        return this.props.workDay ? <div style={{ width: "300px", margin: "0 auto" }}>
            <List component="nav">
                <ListItem divider dense>
                    <ListItemText primary={`Date: ${moment(this.props.workDay.date).format("ddd DD/MM/YYYY")}`} />
                    <span style={{float: "right", fontSize: "small"}}>Total: {this._totalHoursWorked} hours</span>
                </ListItem>
                <ListItem divider dense>
                    <ListItemText primary={`Time in: ${this.props.workDay.timeIn}`} />
                </ListItem>
                <ListItem divider dense>
                    <ListItemText primary={`Time out: ${this.props.workDay.timeOut}`} />
                </ListItem>
                <ListItem divider dense button onClick={() => { this._breaksOpen = !this._breaksOpen }}>
                    <ListItemText primary={`Breaks (total ${this._totalBreakTime} min)`} />
                    {this._breaksOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this._breaksOpen}>
                <List>
                    {this._renderBreaks()}
                </List>
                    
                </Collapse>
            </List>
            <p>
                <Button onClick={e => {
                    e.preventDefault();
                    router.setRoute({
                        pathname: ValidPaths.editWorkday,
                        props: { postId: this.props.postId }
                    })
                }}>Edit this Work day</Button>
            </p>
            <BottomMenu workDay={this.props.workDay} postId={this.props.postId} />
        </div> : null;
    }
}