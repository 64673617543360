import axios from "axios";
import { auth, initializeApp } from "firebase";

export interface IConfig {
    baseUrl: string;
    getPostBaseUrl: string;
    auth: auth.Auth;
    headerAuth: any;
    dateFormat: string;
}

class Config implements IConfig {
    baseUrl!: string;
    getPostBaseUrl: string;
    auth!: auth.Auth;
    headerAuth: any;
    dateFormat = "YYYY-MM-DD";
    
    constructor() {
        initializeApp({
            apiKey: "AIzaSyDkRE2MvMV2BYKyigIG8ePoq9bCl8ZYqKg",
            authDomain: "time-tracker-39995.firebaseapp.com",
            databaseURL: "https://time-tracker-39995.firebaseio.com",
            projectId: "time-tracker-39995",
            storageBucket: "time-tracker-39995.appspot.com",
            messagingSenderId: "365417061857",
            appId: "1:365417061857:web:2d5bcf750f21773b"
        });
        this.getPostBaseUrl = "/restapi/"
    }

    validateToken(token: string) {
        return axios({
            url: this.baseUrl + "wp-json/jwt-auth/v1/token/validate",
            method: "POST",
            headers: {
                Authorization: "Bearer " + token
            }
        });
    }

    loadToken = () => {
        return new Promise((resolve, reject) => {
            auth().onAuthStateChanged(user => {
                if (user) {
                    resolve()
                }
                else {
                    reject(new Error("Please sign in"));
                }
            })
        });
    }
}

export const config = new Config();