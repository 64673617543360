import { observable } from "mobx";

enum ValidPaths {
    root = "root",
    addWorkday = "addWorkday",
    editWorkday = "editWorkday",
    showWorkday = "showWorkday",
    showWeeklyReport = "showWeeklyReport"
}
interface IRouterOpts {
        pathname: ValidPaths;
        props: any;
}

interface IRouter {
    opts: IRouterOpts,
    setRoute: (route: { pathname: ValidPaths, props: any }) => void;
}

class Router implements IRouter {
    @observable opts!: IRouterOpts
    constructor() {
        this.setRoute({pathname: ValidPaths.root, props: null})
    }
    setRoute = (route: { pathname: ValidPaths, props: any }) => {
        this.opts = route;
    }
}

const router = new Router();

export { router, ValidPaths };