import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { auth } from "firebase";
import { config } from "config/Config";
import { store } from "./Store";

interface ITimeRange {
    start: string;
    end: string;
}

export interface IWorkDayData {
    date: string,
    timeBreaks: ITimeRange[],
    timeIn: string,
    timeOut: string,
    extraHrs: ITimeRange[]
}

class TimeTrackerService {
    get usersToken() {
        const user = auth().currentUser;
        return user ? user.getIdToken().then(token => `Bearer ${token}`) : Promise.reject("Login first");
    }

    signIn = (username: string, pass: string) => {
        return auth().signInWithEmailAndPassword(username, pass);
    }
    signOut = () => {
        return auth().signOut();
    }
    getPost = async (date: string) => {
        store.loadingState();
        try {
            const token = await this.usersToken;
            store.clearState();
            return await axios({
                method: "get",
                url: `${config.getPostBaseUrl}searchWorkdays?slug=${date}`,
                headers: {
                    Authorization: token
                }
            }) as AxiosResponse<IWorkDayData>;
        } catch (e) {
            store.errorState();
            throw e;
        }
    };

    getPostByPostId = async (postId: string) => {
        store.loadingState();
        try {
            const token = await this.usersToken;
            const res =  await axios({
                method: "get",
                url: `${config.getPostBaseUrl}getWorkday?doc=${postId}`,
                headers: {
                    Authorization: token
                }
            })
            store.clearState();
            return res.data;
        } catch (e) {
            store.errorState()
            throw e;
        }
    };

    getTimeRangeString = (timeRange: ITimeRange[]) => {
        return timeRange.map((tb: any, i: number) => {
            return tb.start + "-" + tb.end
        })
    }

    getWeekDateRange = (offset: number) => {
        const inDay = moment().isoWeekday(1).add(offset, "week").format(config.dateFormat);
        const outDay = moment().isoWeekday(7).add(offset, "week").format(config.dateFormat);
        return {
            inDay: inDay,
            outDay: outDay
        }
    }

    getWeekData = async (offset: number) => {
        store.loadingState();
        try {
            const token = await this.usersToken;
            const weekDateRange = this.getWeekDateRange(offset);
            const callRes = await axios({
                method: "get",
                url: `${config.getPostBaseUrl}searchWorkDaysByDateRange?from=${weekDateRange.inDay}&to=${weekDateRange.outDay}`,
                headers: {
                    Authorization: token
                }
            })
            store.clearState();
            return callRes.data as IWorkDayData[];
        } catch (e) {
            store.errorState();
            throw e;
        }
    }

    editPost = async (req: IWorkDayData) => {
        store.loadingState();
        try {
            const token = await this.usersToken;
            const res = await axios({
                method: "post",
                url: `${config.getPostBaseUrl}editWorkday`,
                data: req,
                headers: {
                    Authorization: token
                }
            })
            store.clearState();
            return res.data;
        } catch (e) {
            store.errorState();
            throw e;
        }
    }

    addPost = this.editPost;

    convertHoursTimestamp = (hours: string) => {
        return moment(moment().format("YYYY-MM-DD") + "T" + hours, "YYYY-MM-DDTHH:mm").valueOf();
    };

    convertMinToHrs = (min: number) => {
        const hrs = Math.floor(min / 60);
        const mins = Math.floor((min / 60 - hrs) * 60);
        return `${hrs}:${mins}`;
    };

    getTotalBreakTime(workDayData: IWorkDayData): number {
        const breaks = workDayData.timeBreaks;
        let totalBreakTime: number = 0;
        if (breaks) {
            breaks.forEach(tb => {
                const from = this.convertHoursTimestamp(tb.start);
                const to = this.convertHoursTimestamp(tb.end);
                totalBreakTime += to - from;
            })
        }
        return moment.duration(totalBreakTime).asMinutes();
    };

    getTotalHoursWorked(workDayData: IWorkDayData): number {
        const timeIn = this.convertHoursTimestamp(workDayData.timeIn);
        const timeOut = this.convertHoursTimestamp(workDayData.timeOut);
        const extraHrs = workDayData.extraHrs;
        let out = moment.duration(timeOut - timeIn).asMinutes();
        let totalExtraHrs = 0;
        if (extraHrs) {
            extraHrs.forEach(eh => {
                const from = this.convertHoursTimestamp(eh.start);
                const to = this.convertHoursTimestamp(eh.end);
                totalExtraHrs += to - from;
            })
        };
        out += moment.duration(totalExtraHrs).asMinutes();
        return out;
    };

    getWorkTimeForDay(workDayData: IWorkDayData, unit: "hrs" | "mins"): number | string {
        const totalMinsWorked = this.getTotalHoursWorked(workDayData) - this.getTotalBreakTime(workDayData);
        return unit === "mins" ? totalMinsWorked : this.convertMinToHrs(totalMinsWorked);
    }
}

export const service = new TimeTrackerService();