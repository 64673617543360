import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Home from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import TrendingUp from "@material-ui/icons/TrendingUp";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { router, ValidPaths } from "../config/Router";
import { AppBar } from "@material-ui/core";
import { service } from "service/timeTracker.service";

class TopMenu extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            path: "/"
        }
    };

    signOut = () => {
        service.signOut()
            .then(() => {
                document.location.reload();
            })
    }

    render() {
        return <AppBar position="sticky" style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            marginBottom: "1em"
        }}>
            <IconButton color="inherit" aria-label="Menu" onClick={() => {
                router.setRoute({
                    pathname: ValidPaths.root,
                    props: null
                })
            }}><Home /></IconButton>
            <IconButton color="inherit" aria-label="Menu" onClick={() => {
                router.setRoute({
                    pathname: ValidPaths.addWorkday,
                    props: null
                })
            }}><Add /></IconButton>
            <IconButton color="inherit" aria-label="Menu" onClick={() => {
                router.setRoute({
                    pathname: ValidPaths.showWeeklyReport,
                    props: null
                })
            }}><TrendingUp /></IconButton>
            <IconButton color="inherit" aria-label="Menu" onClick={this.signOut}><ExitToApp /></IconButton>
        </AppBar>
    }
}

export default TopMenu;