import * as React from "react";
import Add from "@material-ui/icons/Add";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import { Edit } from "@material-ui/icons";
import { IWorkDayData } from "service/timeTracker.service";
import { router, ValidPaths } from "config/Router";

interface IBottomNavProps {
    workDay: IWorkDayData;
    postId: string
}

class BottomMenu extends React.Component<IBottomNavProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            path: "/"
        }
    };

    clickHandler = () => {
        console.log(this.props)
        router.setRoute({
            pathname: ValidPaths.editWorkday,
            props: {
                postId: this.props.postId
            }
        });
    }

    get renderButtons(): React.ReactNodeArray {
        const out: React.ReactNodeArray = [];
        if (!this.props.workDay.timeIn) {
            out.push(<BottomNavigationAction style={{ color: "white" }} label="Start work" icon={<Add />} key="1" />);
        } else if (this.props.workDay.timeIn && this.props.workDay.timeOut) {
            out.push(<BottomNavigationAction onClick={this.clickHandler} style={{ color: "white" }} label="Edit record" icon={<Edit />} key="1" />);
        }
        return out;
    }

    render() {
        return <BottomNavigation showLabels style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: indigo[500]
        }} >
            {this.renderButtons}
        </BottomNavigation>
    }
}

export default BottomMenu;