import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import { config } from './config/Config';
import App from './App';
import { SignIn } from './component/SignIn';
// import registerServiceWorker from './registerServiceWorker';

config.loadToken().then(() => {
  ReactDOM.render(
    <App />,
    document.getElementById('root') as HTMLElement
  )
}).catch(_e => {
  ReactDOM.render(
    <SignIn />,
    document.getElementById('root') as HTMLElement
  )
});
// registerServiceWorker();
