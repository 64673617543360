import * as React from 'react';
import './App.scss';

import { MainScreen } from './component/MainScreen';
import { AddWorkDay } from './component/AddWorkDay';
import { observer } from 'mobx-react';
import TopMenu from './component/TopMenu';
import EditWorkDay from './component/EditWorkDay';
import { router, ValidPaths } from "./config/Router";
import ShowWeeklyReport from './component/ShowWeeklyReport';
import { Loading } from 'component/Loading';

@observer
class App extends React.Component {
  public render() {
    let content;
    if (router.opts.pathname === ValidPaths.root) content = <MainScreen {...router.opts.props} />
    if (router.opts.pathname === ValidPaths.addWorkday) content = <AddWorkDay {...router.opts.props} />
    if (router.opts.pathname === ValidPaths.editWorkday) content = <EditWorkDay {...router.opts.props} />
    if (router.opts.pathname === ValidPaths.showWeeklyReport) content = <ShowWeeklyReport {...router.opts.props} />

    return (
      <div className="App">
        <TopMenu />
        <Loading></Loading>
        {content}
      </div>
    );
  }
}

export default App;
