import * as React from "react";
import { observer } from "mobx-react";
import { observable, computed, reaction } from "mobx";
import { Table, TableHead, TableCell, TableBody, TableRow, Button } from "@material-ui/core";
import { router, ValidPaths } from "../config/Router";
import { service, IWorkDayData } from "service/timeTracker.service";

@observer
class ShowWeeklyReport extends React.Component<any, any> {
    @observable offset: number;
    @observable daysArray: IWorkDayData[] = [];
    @computed private get _inDay() {
        return service.getWeekDateRange(this.offset).inDay;
    };
    @computed private get _outDay() {
        return service.getWeekDateRange(this.offset).outDay;
    };

    @computed
    get daysArrayProcessed() {
        return this.daysArray.map(d => {
            d.timeBreaks = d.timeBreaks || [];
            d.extraHrs = d.extraHrs || [];
            return d;
        })
    }
    constructor(props: any) {
        super(props);
        reaction(
            () => {
                const offset = this.offset;
                return offset;
            }, async (offset) => {
                try {
                    if (![undefined, null, NaN].includes(offset)) {
                        this.daysArray = await service.getWeekData(offset);
                    } else {
                        throw new Error("Error occurred... ")
                    }
                } catch (e) {
                    this.daysArray = [];
                }
            }
        );
        this.offset = 0;
    }

    private getTotalWeekWorkTime = () => {
        let total = 0;
        this.daysArray.forEach(workdayData => {
            if (workdayData) {
                total += workdayData ? service.getWorkTimeForDay(workdayData, "mins") as number : 0;
            }
        });
        return service.convertMinToHrs(total);
    };

    render() {
        return <div>
            <div>{this._inDay} - {this._outDay}</div>
            <div>
                <Button color="secondary" onClick={() => { this.offset-- }}>-</Button>
                <span>
                    Total hours this weeks: {this.getTotalWeekWorkTime()}
                </span>
                <Button disabled={this.offset >= 0} color="secondary" onClick={() => { this.offset++ }}>+</Button>
            </div>
            {this.daysArray.length > 0
                ? <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Start</TableCell>
                            <TableCell>End</TableCell>
                            <TableCell>Breaks</TableCell>
                            <TableCell>Extra hours</TableCell>
                            <TableCell>Total hours</TableCell>
                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.daysArrayProcessed.map((d: IWorkDayData, i2: number) => {
                                return <TableRow key={i2}>
                                    <TableCell>{d.date}</TableCell>
                                    <TableCell>{d.timeIn}</TableCell>
                                    <TableCell>{d.timeOut}</TableCell>
                                    <TableCell>{service.getTimeRangeString(d.timeBreaks || []).map(ds => <span>{ds}</span>)}</TableCell>
                                    <TableCell>{service.getTimeRangeString(d.extraHrs || []).map(ds => <span>{ds}</span>)}</TableCell>
                                    <TableCell>{service.getWorkTimeForDay(d, "hrs")}</TableCell>
                                    <TableCell><Button color="primary" onClick={e => {
                                        e.preventDefault();
                                        const workDayData = this.daysArray.find(d2 => d2.date === d.date);
                                        if (workDayData) {
                                            router.setRoute({
                                                pathname: ValidPaths.editWorkday, props: {
                                                    postId: workDayData.date
                                                }
                                            })
                                        }
                                    }}>Edit</Button></TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
                : null}
        </div>
    }
}

export {
    ShowWeeklyReport as default,
};