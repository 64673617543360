import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import { router, ValidPaths } from "../config/Router";
import { IMainScreenProps } from "./MainScreen";
import { config } from "config/Config";
import { IWorkDayData, service } from "service/timeTracker.service";

const initialTimeBreak = {
    start: "12:00",
    end: "13:00"
}; const initialExtrsHrs = {
    start: "12:00",
    end: "13:00"
};
export interface ExtrsHrs {
    start: string;
    end: string;
};

@observer
export class AddWorkDay extends React.Component<any, any> {
    @observable private _date = moment().format(config.dateFormat);
    @observable private _timeIn = "07:30";
    @observable private _timeOut = "17:30";
    @observable private _timeBreaks = [initialTimeBreak];
    @observable private _extraHrs: ExtrsHrs[] = [];

    private _timeInChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this._timeIn = e.target.value;
    }
    private _timeOutChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this._timeOut = e.target.value;
    }
    private _BreakInChange = (e: React.ChangeEvent<any>, i: number) => {
        this._timeBreaks[i].start = e.target.value;
    }
    private _breakOutChange = (e: React.ChangeEvent<any>, i: number) => {
        this._timeBreaks[i].end = e.target.value;
    }
    private _extraHrsInChange = (e: React.ChangeEvent<any>, i: number) => {
        this._extraHrs[i].start = e.target.value;
    }
    private _extraHrsOutChange = (e: React.ChangeEvent<any>, i: number) => {
        this._extraHrs[i].end = e.target.value;
    }
    private _dateChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this._date = e.target.value;
    }
    private _renderBreaks = () => {
        return this._timeBreaks.map((timeBreak, i) => {
            return <div key={i}>
                <TextField type="time" label="Break start" value={timeBreak.start} onChange={e => { this._BreakInChange(e, i) }} />
                <TextField type="time" label="Break end" value={timeBreak.end} onChange={e => { this._breakOutChange(e, i) }} />
                <Button color="secondary" onClick={() => { if (this._timeBreaks.length > 1) this._timeBreaks.splice(i, 1) }}>-</Button>
            </div>
        });
    }
    private _renderExtraHrs = () => {
        return this._extraHrs.map((extraHrs, i) => {
            return <div key={i}>
                <TextField type="time" label="Break start" value={extraHrs.start} onChange={e => { this._extraHrsInChange(e, i) }} />
                <TextField type="time" label="Break end" value={extraHrs.end} onChange={e => { this._extraHrsOutChange(e, i) }} />
                <Button color="secondary" onClick={() => { this._extraHrs.splice(i, 1) }}>-</Button>
            </div>
        });
    }
    private _addPost = () => {
        const content: IWorkDayData = {
            date: this._date,
            timeIn: this._timeIn,
            timeOut: this._timeOut,
            timeBreaks: this._timeBreaks,
            extraHrs: []
        };
        return service.addPost(content).then(() => {
            const mainScreenProps: IMainScreenProps = { date: this._date }
            router.setRoute({
                pathname: ValidPaths.root,
                props: mainScreenProps
            })
        })
    }
    private _save = () => {
        service.getPostByPostId(this._date)
            .then(res => {
                const data = res;
                if (!data) {
                    this._addPost();
                } else {
                    alert("post exists");
                }
            })
    }
    render() {
        return <div>
            <TextField type="date" label="Choose date" value={this._date} onChange={this._dateChange} />
            <TextField type="time" label="Time in" value={this._timeIn} onChange={this._timeInChange} />
            <TextField type="time" label="Time out" value={this._timeOut} onChange={this._timeOutChange} />
            <div>
                <label>Breaks</label>
                {this._renderBreaks()}
                <Button color="primary" onClick={() => { this._timeBreaks.push(initialTimeBreak) }}>+</Button>
            </div>
            <div>
                <label>Extra hours</label>
                {this._renderExtraHrs()}
                <Button color="primary" onClick={() => { this._extraHrs.push(initialExtrsHrs) }}>+</Button>
            </div>
            <Button onClick={this._save}>Add work day</Button>
        </div>
    }
}