import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import { ShowWorkDay } from "./ShowWorkDay";
import { IWorkDayData, service } from "service/timeTracker.service";
import { config } from "config/Config";

export interface IMainScreenProps {
    date?: string;
}

@observer
export class MainScreen extends React.Component<IMainScreenProps, any> {
    @observable private _date!: string;
    @observable private _workdayData: IWorkDayData | undefined;

    componentDidMount() {
        this._date = moment(this.props.date).format(config.dateFormat);
        this._getWorkdayDate();
    }

    private _getWorkdayDate = () => {
        this._workdayData = undefined;
        this._getPost(this._date).then(data => {
            if (data) {
                this._workdayData = data;
            }
        })
    }

    private _dateChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this._date = e.target.value;
        this._getWorkdayDate();
    }

    private _getPost = (date: string) => {
        return service.getPostByPostId(date)
            .then(res => {
                return res
            })
            .catch(e => {
                console.log(e)
            })
    };
    render() {
        return <div>
            {moment(this._date).format("ddd")} <TextField type="date" label="Choose date" value={this._date} onChange={this._dateChange} />
            {this._workdayData ? <ShowWorkDay workDay={this._workdayData} postId={this._workdayData.date} /> : <div>No record found for {this._date}</div>}
        </div>
    }
}