import * as React from "react";
import { TextField, Button } from "@material-ui/core";
import { service } from "../service/timeTracker.service";

import '../App.scss';
import './SignIn.scss';

interface IState {
    username: string,
    password: string
}

export class SignIn extends React.Component<any, IState> {
    state = {
        username: "",
        password: ""
    }

    setUsername = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        this.setState({
            username: target.value
        })
    }
    setPassword = (e: React.KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        this.setState({
            password: target.value
        })
    }
    signIn = () => {
        service.signIn(this.state.username, this.state.password)
            .then(() => {
                document.location.reload();
            })
    };

    render() {
        return (
            <div className="App signin">
                <h3>Sign in</h3>
                <div>
                    <TextField label="Username" onKeyUp={this.setUsername} />
                </div>
                <div>
                    <TextField type="Password" label="password" onKeyUp={this.setPassword} />
                </div>
                <div>
                    <Button color="primary" onClick={this.signIn}>Sign in</Button>
                </div>
            </div>
        )
    }
}