import { observable, computed, action } from "mobx";

export type IState = undefined | "loading" | "error"
export interface IStore {
    state: IState;
    setState(state: IState): void;
    clearState(state: IState): void;
    loadingState(): void;
    errorState(): void;
}

class Store implements IStore {
    @observable private _state: IState;

    @computed
    get state() {
        return this._state;
    }

    @action
    setState = (state: IState) => {
        this._state = state;
    }

    clearState = () => {
        this.setState(undefined);
    }

    loadingState = () => {
        this.setState("loading");
    }

    errorState = () => {
        this.setState("error");
    }
}

export const store = new Store();