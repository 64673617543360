import React from "react";
import { observer } from "mobx-react";
import { store } from "service/Store";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

@observer
export class Loading extends React.Component {
    get action() {
        return <Button variant="flat" style={{color: "#aaa"}} onClick={store.clearState}>dismiss</Button>
    }
    render() {
        const props: SnackbarProps = {
            action: this.action,
            open: store.state === "error",
            anchorOrigin: {
                horizontal: "center",
                vertical: "top"
            },
            onClose: store.clearState,
            color: "Error",
            message: "Error occurred"
        }
        return (
            <div className="loading-container">
                <div className="loading-children">
                    {this.props.children}
                </div>
                {store.state === "loading"
                    ? <LinearProgress />
                    : store.state === "error"
                        ? <Snackbar {...props} />
                        : null
                }
                {store.state && <div className="loading-overlay" />}
            </div>
        )
    }
}