import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { router, ValidPaths } from "../config/Router";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Cancel from "@material-ui/icons/Cancel";
import moment from "moment";
import { config } from "../config/Config";
import { IWorkDayData, service } from "service/timeTracker.service";

const initialTimeBreak = {
    start: "12:00",
    end: "13:00"
};
const initialExtrsHrs = {
    start: "12:00",
    end: "13:00"
};

interface IEditWorkDayProps {
    postId: string;
}
class EditWorkDay extends React.Component<IEditWorkDayProps, IWorkDayData> {
    constructor(props: any) {
        super(props);
        this.state = {
            date: moment().format(config.dateFormat),
            timeBreaks: [],
            timeIn: "",
            timeOut: "",
            extraHrs: []
        }
    }

    componentDidMount() {
        this._getPost(this.props.postId).then(res => {
            this.setState(res);
        })
    }

    private _getPost = async (postId: string) => {
        return (await service.getPostByPostId(postId));
    };

    private _editPost = () => {
        return service.editPost(this.state).then(() => {
            router.setRoute({
                pathname: ValidPaths.root,
                props: {
                    date: this.state.date
                }
            })
        })
    };

    private _BreakInChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, index: number) => {
        const breaks = this.state.timeBreaks;
        breaks[index].start = e.target.value;
        this.setState({ timeBreaks: breaks });
    }

    private _BreakOutChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>, index: number) => {
        const breaks = this.state.timeBreaks;
        breaks[index].end = e.target.value;
        this.setState({ timeBreaks: breaks });
    }
    private _extraHrsInChange = (e: React.ChangeEvent<any>, i: number) => {
        const extraHrs = this.state.extraHrs;
        extraHrs[i].start = e.target.value;
        this.setState({
            extraHrs: extraHrs
        })
    }
    private _extraHrsOutChange = (e: React.ChangeEvent<any>, i: number) => {
        const extraHrs = this.state.extraHrs;
        extraHrs[i].end = e.target.value;
        this.setState({
            extraHrs: extraHrs
        })
    }
    private _renderBreaks = () => {
        return this.state.timeBreaks.map((timeBreak, i) => {
            return <div key={i}>
                <IconButton color="secondary" onClick={() => {
                    const timeBreaks = this.state.timeBreaks;
                    if (timeBreaks.length > 1) {
                        timeBreaks.splice(i, 1);
                        this.setState({
                            timeBreaks: timeBreaks
                        })
                    }
                }}><Cancel /></IconButton>
                <TextField type="time" label="Break start" value={timeBreak.start} onChange={e => this._BreakInChange(e, i)} />
                <TextField type="time" label="Break end" value={timeBreak.end} onChange={e => this._BreakOutChange(e, i)} />
            </div>
        });
    }
    private _renderExtraHrs = () => {
        return this.state.extraHrs.map((extraHrs, i) => {
            return <div key={i}>
                <IconButton color="secondary" onClick={() => {
                    const extraHrs = this.state.extraHrs;
                    extraHrs.splice(i, 1);
                    this.setState({
                        extraHrs: extraHrs
                    })
                }}><Cancel /></IconButton>
                <TextField type="time" label="Break start" value={extraHrs.start} onChange={e => { this._extraHrsInChange(e, i) }} />
                <TextField type="time" label="Break end" value={extraHrs.end} onChange={e => { this._extraHrsOutChange(e, i) }} />
            </div>
        });
    }

    render() {
        return <div>
            <div>
                <label>Editing {moment(this.state.date).format("ddd DD/MM/YYYY")}</label>
                <hr />
            </div>
            <div>
                <TextField type="time"
                    label="Time in"
                    value={this.state.timeIn}
                    onChange={e => { this.setState({ timeIn: e.target.value }) }} />
                <TextField type="time"
                    label="Time out"
                    value={this.state.timeOut}
                    onChange={e => { this.setState({ timeOut: e.target.value }) }} />
                <hr />

            </div>
            <div>
                <label>Breaks</label>
                {this._renderBreaks()}
                <Button fullWidth color="primary" onClick={() => {
                    const timeBreaks = this.state.timeBreaks;
                    timeBreaks.push(initialTimeBreak);
                    this.setState({
                        timeBreaks: timeBreaks
                    });
                }}><Add /> Add break</Button>
                <hr />
            </div>
            <div>
                <label >Extra hours</label>
                {this._renderExtraHrs()}
                <Button fullWidth color="primary" onClick={() => {
                    const extraHrs = this.state.extraHrs;
                    extraHrs.push(initialExtrsHrs);
                    this.setState({
                        extraHrs: extraHrs
                    })
                }}><Add /> Add after hours</Button>
                <hr />
            </div>
            <Button onClick={this._editPost}>Save</Button>
        </div>
    }
}

export default EditWorkDay;